<template>
	<div id="app">
		<keep-alive>
			<router-view v-if="$route.meta.keepAlive"></router-view>
		</keep-alive>
		<router-view  v-if="!$route.meta.keepAlive"></router-view>
		
		<loading v-if="loading"></loading>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	
	export default {
		mounted() {
			const that = this;
			document.getElementById('window_loading').remove();
			that.setHtmlFontSize();
			that.insertBaidu();
			window.onresize = () => {
				that.setHtmlFontSize();
			};
		},
		methods: {
			// 设置html字体大小
			setHtmlFontSize () {
				var windowWidth = document.body.clientWidth;
				var htmlFontSize = 0;
				var basicWindowWidth = 375;
				var basicHtmlFontSize = 100;
				windowWidth > 750 && (windowWidth = 750);
				windowWidth < 320 && (windowWidth = 320);
				htmlFontSize = basicHtmlFontSize * (windowWidth / basicWindowWidth);
				document.getElementsByTagName("html")[0].style.fontSize = htmlFontSize + "px";
			},
			// 引入百度统计
			insertBaidu() {
				var _hmt = _hmt || [];
				var hm = document.createElement("script");
				hm.src = "https://hm.baidu.com/hm.js?45a2425c1256a09509531bd1954df366";
				var s = document.getElementsByTagName("script")[0]; 
				s.parentNode.insertBefore(hm, s);
			}
		},
		watch: {
			$route(){
				// console.log(111);
				let ele = document.documentElement || document.body;
				ele.scrollTop = 0;
			}
		},
		computed: {
			...mapState([
				'loading'
			])
		}
	}
</script>

<style>
/*css 初始化 */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, menu, nav, output, ruby, section, summary, time, mark, audio, video, input, textarea, button, select{ box-sizing: border-box;margin: 0;padding: 0;border: none;outline:none;word-break: break-all;background: transparent; }
body{ min-width: 320px;max-width: 750px;background: #fff;overflow-x: hidden;font-family: '思源黑体', 'Helvetica Neue', Helvetica, Arial, sans-serif;font-weight: normal;font-size: 12px;color: #000;line-height: 24px; }
h1,h2,h3,h4,h5,h6,input,button,textarea,th,a,select{ font-size: 12px;color: #000;font-weight: normal; }
fieldset, img, input, button { border: none;padding: 0;margin: 0;outline-style: none; }
button { display: inline-block;position: relative;background: #2b53c6;color: #fff;overflow: hidden;vertical-align: top; }
button.disabled{ background: #dcdcdc;color: #030303; }
button:focus{ outline: none; }
button:after{ content: "";display: block;position: absolute;top: 0;left: 0;right: 0;bottom: 0;pointer-events: none;background-color: #ccc;opacity: 0;transition: all .3s; }
button:active:after{ opacity: .3;transition: 0s; }
ul, ol { list-style: none; }
select, input, textarea { border: none;outline:none; }
textarea { outline: none;resize: none; }
video{ border: none;outline: none; }

/*防止拖动*/
img { border: 0;vertical-align: middle; }
/*  去掉图片低测默认的3像素空白缝隙*/
table { border-collapse: collapse;border-spacing: 0; }
a { display: inline-block;position: relative;text-decoration: none;vertical-align: top; }
s, i, em { font-style: normal;text-decoration: none; }

.clear{ height: 0; line-height: 0; font-size: 0; clear: both;}
.clear_both:after{content: ""; display: block; height: 0; clear: both; visibility: hidden; font-size: 0;}

.text_one{ overflow: hidden;text-overflow:ellipsis;white-space: nowrap; }
.text_two{ display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden; }
.text_three{ display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden; }
.text_four{ display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 4;overflow: hidden; }
.text_five{ display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 5;overflow: hidden; }

.text_theme{ color: #2b53c6!important; }
.text_underline:after{ content: '';display: block;position: absolute;width: 100%;height: 3px;bottom: 0;left: 0;background: #2b53c6; }
.bg_theme{ background-color: #2b53c6!important; }
.border_theme{ border-color: #2b53c6!important; }

.border_t:after{ content: '';display: block;position: absolute;width: 100%;height: 1px;top: 0;left: 0;background: #e8e8e8; }
.border_r:after{ content: '';display: block;position: absolute;width: 1px;height: 100%;top: 0;right: 0;background: #e8e8e8; }
.border_b:after{ content: '';display: block;position: absolute;width: 100%;height: 1px;bottom: 0;left: 0;background: #e8e8e8; }
.border_l:after{ content: '';display: block;position: absolute;width: 1px;height: 100%;top: 0;left: 0;background: #e8e8e8; }

.fade_transition-enter-active,.fade_transition-leave-active {
	transition: opacity .3s ease-out;
}
.fade_transition-enter,.fade_transition-leave-to {
	opacity: 0;
}

.slide_transition-enter-active,.slide_transition-leave-active {
	transition: all .3s ease-out;
}
.slide_transition-enter, .slide_transition-leave-to {
	opacity: 0;
	transform: translate(0, 100px);
}

.scale_transition-enter-active,.scale_transition-leave-active {
	transition: all .3s ease-out;
}
.scale_transition-enter, .scale_transition-leave-to {
	transform: scale(0, 0);
}
</style>
