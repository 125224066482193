import './plugins/swiper/swiper.min.css';
import Vue from 'vue';
import App from './App.vue';
import router from './router/index.js';
import store from './store/index.js';
import Loading from './components/loading/loading.vue';
import NetworkErr from './components/network_err/network_err.vue';
import Empty from './components/empty/empty.vue';
import Swiper from './plugins/swiper/swiper.min.js';
// 1
Vue.config.productionTip = false;

Vue.prototype.Swiper = Swiper;

Vue.component('Loading', Loading);
Vue.component('NetworkErr', NetworkErr);
Vue.component('Empty', Empty);

new Vue({
	render: h => h(App),
	router,
	store
}).$mount('#app');
