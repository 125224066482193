import Axios from 'axios';
import store from '../store/index.js';

/* eslint-disable */
// const AUTH_URL = "http://192.168.11.17:13149/gateway/auth/";
// const BACKSTAGE_URL = "http://192.168.11.17:13149/gateway/backstage/";
// const GAME_URL = "http://192.168.11.17:13149/gateway/game/";
// const TRAIN_URL = "http://192.168.11.17:13149/gateway/training/";
// const VISION_URL = "http://192.168.11.17:13149/gateway/olduflyvision/";

// 预上线域名
// const AUTH_URL = "http://47.98.196.52:13149/gateway/auth/";
// const BACKSTAGE_URL = "http://47.98.196.52:13149/gateway/backstage/";
// const GAME_URL = "http://47.98.196.52:13149/gateway/game/";
// const TRAIN_URL = "http://47.98.196.52:13149/gateway/training/";
// const VISION_URL = "http://47.98.196.52:13149/gateway/olduflyvision/";

// 正式域名
// const AUTH_URL = "https://api00.uflycloud.com:13149/gateway/auth/";
// const BACKSTAGE_URL = "https://api00.uflycloud.com:13149/gateway/backstage/";
// const GAME_URL = "https://api00.uflycloud.com:13149/gateway/game/";
// const TRAIN_URL = "https://api00.uflycloud.com:13149/gateway/training/";
// const VISION_URL = "https://api00.uflycloud.com:13149/gateway/olduflyvision/";

const COURSE_URL = "https://api00.uflycloud.com:13149/gateway/course/";
// const COURSE_URL = "https://tencent.uflycloud.com:13149/gateway/course/";

// 设置请求超时时间
Axios.defaults.timeout = 15000;
// 刷新Token，当token过期时重新登录

const getHttp = (url, obj, isAuth) => {
	let getData = "";
	let config = {};
	if (isAuth) {
		config = {
			headers: { 
				'content-type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${ store.state.safeInfo.kj }`,
				'accessToken': store.state.safeInfo.kt
			},
			method: "get",
			baseURL: '/'
		};
	} else {
		config = {
			headers: { 
				'content-type': 'application/x-www-form-urlencoded'
			},
			method: "get",
			baseURL: '/'
		};
	}
	for (let k in obj) {
		getData += `${ k }=${ obj[k] }&`;
	}
	
	if (getData) {
		getData = `?${ getData }`;
		getData = getData.slice(0, getData.length - 1);
		url += getData;
	}
	return new Promise((resolve, reject) => {
		Axios.get(url, config).then((res) => {
			resolve(res);
		}).catch((e) => {
			reject(e);
		});
	});
}

const postHttp = (url, obj, isAuth, isJson) => {
	// window.console.log(store.state.kf_web_loginInfo);
	let postData = "";
	let config = {};
	if (isAuth) {
		isJson 
		? (config = {
			headers: { 
				'content-type': 'application/json',
				'Authorization': `Bearer ${ store.state.safeInfo.kj }`,
				'accessToken': store.state.safeInfo.kt
			},
			method: "post",
			baseURL: '/'
		})
		: (config = {
			headers: { 
				'content-type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${ store.state.safeInfo.kj }`,
				'accessToken': store.state.safeInfo.kt
			},
			method: "post",
			baseURL: '/'
		});
	} else {
		isJson
		? (config = {
			headers: { 
				'content-type': 'application/json'
			},
			method: "post",
			baseURL: '/'
		})
		: (config = {
			headers: { 
				'content-type': 'application/x-www-form-urlencoded'
			},
			method: "post",
			baseURL: '/'
		});
	}
	if (!isJson) {
		for (let k in obj) {
			postData += `${ k }=${ obj[k] }&`;
		}
		postData && (postData = postData.slice(0, postData.length - 1));
	} else {
		postData = obj;
	}
	return new Promise((resolve, reject) => {
		Axios.post(url, postData, config).then((res) => {
			resolve(res);
		}).catch((e) => {
			reject(e);
		});
	});
}

const putHttp = (url, obj, isAuth, isJson) => {
	let putData = "";
	let config = {};
	if (isAuth) {
		isJson 
		? (config = {
			headers: { 
				'content-type': 'application/json',
				'Authorization': `Bearer ${ store.state.safeInfo.kj }`,
				'accessToken': store.state.safeInfo.kt
			},
			method: "post",
			baseURL: '/'
		})
		: (config = {
			headers: { 
				'content-type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${ store.state.safeInfo.kj }`,
				'accessToken': store.state.safeInfo.kt
			},
			method: "post",
			baseURL: '/'
		});
	} else {
		isJson
		? (config = {
			headers: { 
				'content-type': 'application/json'
			},
			method: "post",
			baseURL: '/'
		})
		: (config = {
			headers: { 
				'content-type': 'application/x-www-form-urlencoded'
			},
			method: "post",
			baseURL: '/'
		});
	}
	if (!isJson) {
		for (let k in obj) {
			putData += `${ k }=${ obj[k] }&`;
		}
		putData && (putData = putData.slice(0, putData.length - 1));
	} else {
		putData = obj;
	}
	return new Promise((resolve, reject) => {
		Axios.put(url, putData, config).then((res) => {
			resolve(res);
		}).catch((e) => {
			reject(e);
		});
	});
}

export default class Api {
	// 获取首页banner图
	static findHomeBanner(obj) {
		return postHttp(`${ COURSE_URL }common/findBannerPicture`, obj, false, true);
	}
}